import React, { useEffect } from "react";

import { Avatar, Badge } from "antd";
//import { useState,useEffect } from "react";
import { useState } from "react";
import { WebSocketMain } from "../../../WebSocket/defaultsocket";

const ProfileAvatar = ({
  isOnline = false,
  name,
  size = 40,
  offset = [-5, 35],
  src,
  dot = true,
  isAvatarBgColor = false,
  setLastActiveSocket,
  IsOnlineAPI
}) => {
  const [onLoadImgErr, setOnLoadImgErr] = useState(false);
  const [userOnlineStatus, setuserOnlineStatus] = useState(false);

  const [users, setUsers] = useState([]);

  // Function to update the user array based on online status
  const updateUserStatus = (user) => {
    const { userId, online } = user;
    setUsers((prevUsers) => [...prevUsers, user]);
  };

  useEffect(() => {
    WebSocketMain.on("userStatus", (response) => {
      updateUserStatus(response);
      console.log(response,"responseresponse")
    });
  }, []);

  useEffect(() => {
    if(!dot) return;
    setuserOnlineStatus(IsOnlineAPI);
    setLastActiveSocket({ "online": IsOnlineAPI })
    const onlineUser = users.filter((u) => {
      if (u.userId == window?.location?.pathname?.split("/")?.slice(-1)[0]){
        setuserOnlineStatus(u?.online)
        setLastActiveSocket(u);
      }
    });
  }, [users]);

  const handlingfalsyValue = (val) =>
    val ? val : typeof val === "number" ? val : "NA";

  return (
    <>
      <Badge
        color={userOnlineStatus ? "#118936" : "#9e9e9e"}
        className="ProfileAvatar"
        dot={dot}
        offset={offset}
      >
        <Avatar
          size={size}
          src={handlingfalsyValue(src)}
          className={isAvatarBgColor && onLoadImgErr ? "avatarBgColor" : ""}
          onError={() => setOnLoadImgErr(true)}
        >
          {handlingfalsyValue(name?.slice(0, 1)?.toUpperCase())}
        </Avatar>
      </Badge>
    </>
  );
};

export default ProfileAvatar;
