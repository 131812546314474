import React, { useEffect, useRef, useState } from "react";

import { Row, Col, Input, Progress, Alert, Divider } from "antd";

import "./MessageInput.css";

import { CgAttachment } from "react-icons/cg";

import { AiFillCloseCircle } from "react-icons/ai";

import { getCookie, getFileIcon, getFileSizeInfo, handleFileSize } from "../../../../shared/helper";

// import { WebSocketMain } from "../../../../WebSocket/defaultsocket";

import { useDispatch, useSelector } from "react-redux";

// import { sendMessage_Attachment } from "../../../redux/actions";

import axios from "axios";

// import { fetchToken } from "../../../API/API";

// import Infomessage from "../../../components/Infomessage";

import { useParams } from "react-router-dom";

const MessageInput = ({
  setAlertMsg,
  chatDetails,
  currentUser,
  jobTitle,
  conversationId,
  msgContainerRef,
  connectedState,
  WebSocketMain,
}) => {
  // dummy
  let WS;
  let fetchToken;
  let sendMessage_Attachment;
  const Infomessage = (props) => {
    return <div className={props.class}>{props.message}</div>;
  };

  const inputTextArea = useRef(null);

  const inputAttachmentBox = useRef(null);

  const dispatch = useDispatch();

  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;

  const [attachmentFile, setAttachmentFile] = useState([]);

  const [textArea, setTextArea] = useState(null);

  const [textAreaValue, setTextAreaValue] = useState();

  const [socketFile, setSocketFiles] = useState([]);

  const [fileKey, setFileKey] = useState([]);

  const [UploadLoading, setUploadLoading] = useState(0);

  const [error, setError] = useState(false);

  const [docUploadcheck, setDocUploadcheck] = useState(false);

  const [onSubmitCheck, setOnSubmitCheck] = useState(false);

  //Reducers

  const uploadFileRes = useSelector(
    (state) => state?.messagesReducer?.uploadFileRes
  );

  const username = useSelector((state) => state.profileReducer?.user);

  // Web socket //////////////////////////////////////////////////////////
  const talentId = useParams()?.id;

  const adminId = useSelector(
    (state) => state.profileReducer?.success?.AccountId
  );

  const [subjectState, setSubjectState] = useState(null);
  ////////////////////////////////////////////////////////////////////////

  // adding s3keys & Urls according to their files

  useEffect(() => {
    if (fileKey.length >= attachmentFile.length) {
      setAttachmentFile(
        attachmentFile.map((res, index) => {
          return {
            ...res,

            FileInfo: {
              FilePath: fileKey[index].file,

              FileName: res.file.name,

              FileSize: res.file.size,

              FileUrl: fileKey[index]?.fileUrl,
            },
          };
        })
      );
    }
  }, [fileKey]);

  // setting an array to use for sockets

  useEffect(() => {
    var tempoArr = [];

    for (let index = 0; index < attachmentFile.length; index++) {
      if (attachmentFile.length > 0) {
        tempoArr = [
          ...tempoArr,

          {
            FileName: attachmentFile[index].file.name,

            FilePath: attachmentFile[index].FileInfo?.FilePath,

            FileSize: attachmentFile[index].file.size,
          },
        ];
      }
    }

    setSocketFiles(tempoArr);
  }, [attachmentFile]);

  // Upload Files on S3 bucket

  async function uploadToBucket() {
    const results = [];

    for (let i = 0; i < attachmentFile.length; i++) {
      try {
        if (attachmentFile[i].FileInfo?.FileUrl) {
          const uploadRequestS3 = await axios.put(
            attachmentFile[i].FileInfo.FileUrl,

            attachmentFile[i].file
          );

          results.push(uploadRequestS3);
        }
      } catch (err) {
        results.push(err);
      }
    }

    return results;
  }

  useEffect(() => {
    setTimeout(() => {
      setDocUploadcheck(false);
    }, 5000);
  }, [docUploadcheck]);

  const onChangeFile = async ({ target: { files } }) => {
    console.log(files,"files")
    let flag = false;
    if (!connectedState){
      WebSocketMain.connect();
    } 

    const length = files.length;

    for (let i = 0; i < length; i++) {
      const fileIcon = getFileIcon(getFileFormat(files[i].name));

      if (fileIcon) {
        const fileSize = getFileSizeInfo(files[i]);
        if (fileSize) {
          setAttachmentFile((prevState) => {
            return [...prevState, { file: files[i] }];
          });

          const payload = {
            type: "msgAttachment",
            contentType: files[0].type,
          };

          const headers = {
            "Content-Type": "application/json",
            Authorization: getCookie("adminToken"),
          };

          // const getUploadUrl = `http://192.168.1.104:4000/development/upload`;

          const getUploadUrl = `${BackendApiBaseUrl}/upload`;

          setUploadLoading(80);

          let response = "";

          try {
            const data = await axios.post(getUploadUrl, payload, { headers });

            response = data.data;

            if (response) {
              setUploadLoading(100);
            }
          } catch (error) {
            setError(true);
          }

          setFileKey([
            ...fileKey,

            {
              file: response?.Key,

              fileUrl: response?.uploadURL,
            },
          ]);
        } else {
          // setErrorTitle('File size bigger than 10 MB')

          setAlertMsg(true);
        }

        if (i === 0 && length === 1 && !fileSize) flag = true;
      } else {
        // setErrorTitle('File format not supported')

        setAlertMsg(true);
      }

      if (i === 0 && length === 1 && !fileIcon) flag = true;
    }

    flag ? getDispayBlock() : getDispayNone("fromFileAttachment");

    // setTimeout(()=>{setUploadLoading(100)},500)
  };

  const onRemoveAttachment = (fileName, e) => {
    if (e && e.stopPropagation) e.stopPropagation();

    setAttachmentFile(() => {
      return attachmentFile.filter((file) => {
        return file.file.name !== fileName;
      });
    });

    var filterKey = attachmentFile.filter((file) => {
      return file.file.name === fileName;
    });

    setFileKey(() => {
      return fileKey.filter((file) => {
        return file.file !== filterKey[0].FileInfo?.FilePath;
      });
    });

    getDispayNone("onRemoveAttachment");
  };

  const getFileFormat = (fileName) => {
    const behideDot = fileName?.lastIndexOf(".") + 1;

    const fileLength = fileName?.length;

    const fileFormat = fileName?.slice(behideDot, fileLength);

    return fileFormat;
  };

  const textAreaAdjust = (element) => {
    const val = element.target.value;

    if (val !== "") {
      element.target.style.height = -2 + element.target.scrollHeight + "px";
    } else {
      element.target.style.height = "40px";

      if (window.innerWidth < 768) element.target.style.height = "53px";
    }

    setTextArea(element);

    setTextAreaValue(element.target.value);
  };

  const onFocusTextArea = () => {
    if (attachmentFile.length !== 0) {
      getMarginInputAttachmentBox("marginTop-15px");
    }
  };

  const getMarginInputAttachmentBox = (str) => {
    switch (str) {
      case "marginTop_0px":
        inputAttachmentBox.current.style.marginTop = "0px";

        break;

      case "marginTop-15px":
        // inputAttachmentBox.current.style.marginTop = "15px";

        break;

      default:
        inputAttachmentBox.current.style.margin = "0px";

        break;
    }
  };

  const handleOnCLickTextAreaFocus = () => {
    getDispayBlock("fromTextWithAttachment");
  };

  const getDispayBlock = (str) => {
    switch (str) {
      case "fromFileAttachment":
        getMarginInputAttachmentBox("marginTop-15px");

        getDispayBlock();

        break;

      case "onRemoveAttachment":
        getMarginInputAttachmentBox("marginTop-15px");

        getDispayBlock();

        if (textArea?.target?.value && attachmentFile.length === 1) {
          getMarginInputAttachmentBox(" marginTop_0px");
        }

        break;

      case "fromTextWithAttachment":
        if (attachmentFile.length !== 0) {
          getMarginInputAttachmentBox("marginTop-15px");

          getDispayBlock();

          inputTextArea.current.focus();
        }

        break;

      default:
        inputTextArea.current.style.display = "block";

        break;
    }
  };

  const getDispayNone = (str) => {
    switch (str) {
      case "fromFileAttachment":
        !textArea?.target?.value
          ? getDispayNone()
          : getDispayBlock("fromFileAttachment");

        break;

      case "onRemoveAttachment":
        !textArea?.target?.value
          ? (inputTextArea.current.style.display = "block")
          : getDispayBlock("onRemoveAttachment");

        break;

      default:
        // inputTextArea.current.style.display = "none";

        getMarginInputAttachmentBox();

        break;
    }
  };

  const AttachmentDiv = ({ file: { name: fileName }, fileSize }) => {
    console.log(fileSize,"{handleFileSize()}")
    return (
      <>
        <div className="attachment-container">
          <div className="attachment-content">
            <img
              src={getFileIcon(getFileFormat(fileName))}
              // alt={fileName}

              className="fileIcon"
            />

            <div className="attachment-content-info">
              <h3>{fileName}</h3>

              <p>{handleFileSize(fileSize?.FileInfo?.FileSize)} {getFileFormat(fileName)}</p>
            </div>
          </div>

          <AiFillCloseCircle
            className="attachment-container-close-btn"
            onClick={(e) => onRemoveAttachment(fileName, e)}
          />
        </div>
      </>
    );
  };

  async function onSubmitHandler() {
    if ((UploadLoading > 0 && UploadLoading < 100) || onSubmitCheck) {
      return;
    }
    setOnSubmitCheck(true);
    let success = await uploadToBucket();
    let failure = [];
    if (success?.length > 0) {
      failure = success?.filter((res) => {
        return res.status !== 200;
      });
    }
    setOnSubmitCheck(false);
    if (failure.length > 0 && attachmentFile.length > 0) {
      setDocUploadcheck(true);
    }
    //send msg in sockets
    inputTextArea.current.value = "";
    inputTextArea.current.style.height = "40px";
    setTextAreaValue("");
    if (/^\s*$/.test(textAreaValue) && attachmentFile.length == 0) {
      return;
    }

    if (failure.length === 0) {
      const messageObj = {
        sender: "Admin",
        adminId: adminId,
        talentId: talentId,
        data: textAreaValue,
        subject: subjectState ? subjectState : null,
        conversationId: conversationId ? conversationId : null,
        Attachments: socketFile.length > 0 ? socketFile : null,
        HasAttachments: attachmentFile.length > 0 ? true : false,
      };
      WebSocketMain.emit("createMessage", messageObj);
    }

    inputTextArea.current.value = "";
    setAttachmentFile([]); //clears the attachment array
    setSubjectState("");
    setFileKey([]);
    // receive msg to display
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // helping in no extra space

      // Enter key is pressed without Shift key
      onSubmitHandler();
    }
  };

  useEffect(() => {
    if (error || docUploadcheck) {
      setAttachmentFile([]);
    }

    setTimeout(() => {
      setError(false);

      setUploadLoading(100);
    }, 3000);
  }, [error || docUploadcheck]);

  return (
    <div
      style={{
        bottom: "5px",

        padding: "0% 1.5% 0% 1.5%",

        width: "100%",

        // position: "absolute",

        position: "relative",

        zIndex: "1",

        backgroundColor: "#fff",
      }}
      className={`send-message-container ${(attachmentFile?.length > 0 && !(UploadLoading > 0 && UploadLoading < 100)) && "Uploading-box-UI" || (UploadLoading > 0 && UploadLoading < 100) && "Uploading-box-UI-pending"}`}
    >
      {docUploadcheck && (
        <Infomessage
          message={"Document failed to upload please try again"}
          class={"container-error"}
        />
      )}

      <div
        className="text-with-attachment"
        style={{
          boxShadow:
            attachmentFile.length > 0 && "0 0 0.25rem 0 rgba(0, 0, 0, 0.25)",
        }}
      >
        <div
          style={{
            borderRadius: "0.25rem",
            boxShadow:
              attachmentFile.length > 0
                ? "none"
                : "0 0 0.25rem 0 rgba(0, 0, 0, 0.25)",
          }}
        >
          <textarea
            name=""
            id=""
            placeholder="Add a subject"
            className="teaxArea-content-subject ant-input"
            onChange={(e) => setSubjectState(e.target.value)}
            // ref={inputTextArea}
            // onFocus={onFocusTextArea}
            value={subjectState}
          ></textarea>
          {/* <Divider className="text-divider-header-msg" /> */}
          <textarea
            autoFocus
            name=""
            id=""
            placeholder="Type your message here..."
            className="teaxArea-content ant-input"
            onChange={(e) => textAreaAdjust(e)}
            ref={inputTextArea}
            onFocus={onFocusTextArea}
            value={textAreaValue}
            onKeyDown={handleKeyPress}
          ></textarea>
        </div>
        <div
          className="attachment-box"
          ref={inputAttachmentBox}
          onClick={handleOnCLickTextAreaFocus}
        >
          {error ? (
            <Alert
              type="error"
              showIcon={false}
              message="Document failed to upload"
              banner
            />
          ) : UploadLoading > 0 && UploadLoading < 100 ? (
            <div className="upload-progress">
              <Progress percent={UploadLoading} status="active" size="small" />
            </div>
          ) : (
            attachmentFile.map((file, i) => (
              <AttachmentDiv key={i} file={file.file} fileSize={file} />
            ))
          )}
        </div>
      </div>

      <div className="message-bottom">
        <div className="conversation-icon">
          <label htmlFor="file">
            <CgAttachment className="conversation-icon" />
          </label>

          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            onChange={(e) => onChangeFile(e)}
            multiple
            value=''
          />
        </div>

        <div className="message-send-btn" onClick={(e) => onSubmitHandler(e)}>
          Send
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
