import { types } from "./action-types";

export const fetchDataFake = (params) => ({
  type: types.SEND_REQUEST,
  payload: params,
});

export const fetchDataSuccess = (response) => ({
  type: types.SEND_REQUEST_SUCCESS,
  payload: response.data,
});

export const fetchDataFailure = (error) => ({
  type: types.SEND_REQUEST_FAILURE,
  payload: {},
  error: error,
});

export const defaultNavigation = (payload) => ({
  type: types.DEFAULT_NAVIGATION,
  payload,
});

// Fetch existing user
export const fetchExistingtUser = (params) => ({
  type: types.CHECK_EXISTING_USER_INIT,
  params,
});
export const fetchExistingUserSuccess = (response) => ({
  type: types.CHECK_EXISTING_USER_SUCCESS,
  payload: response,
});
export const fetchExistingUserFailure = (error) => ({
  type: types.CHECK_EXISTING_USER_FAILURE,
  payload: { valid: false, msg: "User doesn't exist" },
  error: error,
});

export const fetchTableInit = (payload) => ({
  type: types.FETCH_TABLE_INIT,
  payload,
});

export const fetchTableSuccess = (payload) => ({
  type: types.FETCH_TABLE_SUCCESS,
  payload,
});

export const fetchTableFilterSuccess = (payload) => ({
  type: types.FETCH_TABLE_F_SUCCESS,
  payload,
});

export const fetchTableFilterCountSuccess = (payload) => ({
  type: types.FETCH_TABLE_FC_SUCCESS,
  payload,
});

export const fetchTableFailure = (payload) => ({
  type: types.FETCH_TABLE_FAILURE,
  payload,
});

// Search Candiate Result

export const fetchCandiateResults = (params) => ({
  type: types.SEARCH_CANDIATE_RESULTS,
  params,
});
export const fetchCandiateResultsSuccess = (response) => ({
  type: types.SEARCH_CANDIATE_RESULTS_SUCCESS,
  payload: response,
});
export const fetchCandiateResultsFailure = (error) => ({
  type: types.SEARCH_CANDIATE_RESULTS_FAILURE,
  payload: { valid: false, msg: "User doesn't exist" },
  error: error,
});

export const fetchResultsCampaigns = (params) => ({
  type: types.SEARCH_CANDIATE_CAMPAIGNS,
  params,
});
export const fetchCandiateSuccessCampaigns = (response) => ({
  type: types.SEARCH_CANDIATE_CAMPAIGNS_SUCCESS,
  payload: response,
});
export const fetchCandiateFailureCampaigns = (error) => ({
  type: types.SEARCH_CANDIATE_CAMPAIGNS_FAILURE,
  payload: { valid: false, msg: "User doesn't exist" },
  error: error,
});

export const fetchcsvfile = (params) => ({
  type: types.DOWNLOAD_CSV_FILE_INIT,
  params,
});
export const fetchcsvfileSuccess = (response) => ({
  type: types.DOWNLOAD_CSV_FILE_SUCCESS,
  payload: response,
});
export const fetchcsvfileFailure = (error) => ({
  type: types.DOWNLOAD_CSV_FILE_FAILURE,
  payload: { valid: false, msg: "User doesn't exist" },
  error: error,
});
export const collapseToggoleTrue = (payload) => {
  return {
    type: types.COLLAPSE_TOGGLE_TRUE,
    payload,
  };
};
export const collapseToggoleFalse = (payload) => {
  return {
    type: types.COLLAPSE_TOGGLE_FALSE,
    payload,
  };
};
export const fetchUserProfile = (payload) => ({
  type: types.FETCH_PROFILE_INIT,
  payload,
});
export const fetchUserProfileSuccess = (response) => ({
  type: types.FETCH_PROFILE_REQUEST_SUCCESS,
  payload: response,
});
export const fetchUserProfileFailure = (error) => ({
  type: types.FETCH_PROFILE_REQUEST_FAILURE,
  payload: {},
  error: error,
});

export const fetchTalentUserDetails = (payload) => ({
  type: types.FETCH_TALENT_USER_DETAILS,
  payload,
});
export const fetchTalentUserDetailsSuccess = (response) => ({
  type: types.FETCH_TALENT_USER_DETAILS_SUCCESS,
  payload: response,
});
export const fetchTalentUserDetailsFailure = (error) => ({
  type: types.FETCH_TALENT_USER_DETAILS_FAILURE,
  payload: {},
  error: error,
});

export const fetchUserChatHistory = (payload) => ({
  type: types.FETCH_USER_CHAT_HISTORY,
  payload,
});
export const fetchUserChatHistorySuccess = (response) => ({
  type: types.FETCH_USER_CHAT_HISTORY_SUCCESS,
  payload: response,
});
export const fetchUserChatHistoryFailure = (error) => ({
  type: types.FETCH_USER_CHAT_HISTORY_FAILURE,
  payload: {},
  error: error,
});
export const clearUserChatHistory = (payload) => ({
  type: types.CLEAR_USER_CHAT_HISTORY,
  payload: payload,
});
export const authUserTokendata = (payload) => ({
  type: types.AUTH_USER_TOKEN,
  payload: payload,
});

export const fetchEmployerSuggestion = (payload) => ({
  type: types.FETCH_PRIVATE_EMPLOYER,
  payload,
});
export const fetchEmployerSuggestionSuccess = (response) => ({
  type: types.FETCH_PRIVATE_EMPLOYER_SUCCESS,
  payload: response,
});
export const fetchEmployerSuggestionFailure = (error) => ({
  type: types.FETCH_PRIVATE_EMPLOYER_FAILURE,
  payload: {},
  error: error,
});