import { io } from "socket.io-client";
import { getCookie } from "../shared/helper";
import store from "../redux/store";

const url = process.env.REACT_APP_ADMIN_URL;

export const WebSocketMain = io(url, {
  extraHeaders: {
    authorization: store.getState().chatHistoryReducer.authToken, // Set the authorization header
  },
});

WebSocketMain.on("connected", (response) => {
  console.log({ response, WebSocketMain }); // x8WIv7-mJelg7on_ALbx
});
