import React, { useRef, useState } from "react";
import { Input, Radio, Space } from "antd";
import PrivateGigTag from '../../assets/icons/PrivateGigTag.svg'
import { PRIVATE_EMPLOYERS } from "./SearchHeader";
import { useBlurEffect } from "../SearchAndFilter/Components/customFunction";

const SearchRadioButton = (props) => {
  const {
    SetterState,
    ArrayObject,
    HeadingText,
    ValueState,
    setcampaignsSearch,
  } = props;
  return (
    <>
      <div className="search-header-Drower-content searchContainer-top">
        <span className="search-header-Drower-Profession">{HeadingText}</span>
        {HeadingText === "Campaigns" && (
          <span className="Search-campaigns-input">
            <Input
              onChange={(e) => setcampaignsSearch(e.target.value)}
              placeholder="input with clear icon"
              allowClear
            />
          </span>
        )}
        <Radio.Group
          onChange={(e) => {
            e.stopPropagation();
            SetterState(e.target.value);
          }}
          value={ValueState}
        >
          <Space direction="vertical">
            {ArrayObject?.map((data, i) => {
              return (
                <>
                  <Radio
                    value={Object.keys(data)[0]}
                    className="search-header-Drower-radio-one"
                    key={i}
                  >
                    <span className="search-header-Drower-radio-one-span">
                      {Object.keys(data)}{Object.keys(data)[0]==PRIVATE_EMPLOYERS&& <img className="ml-4" src={PrivateGigTag} alt='pvt' />}
                    </span>
                    <span className="search-header-Drower-radio-one-span-1">
                      ({data[Object.keys(data)]})
                    </span>
                  </Radio>
                </>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
    </>
  );
};

export default SearchRadioButton;

export const SearchRadioButtonCampaigns = (props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropDownRef = useRef(null);

  const {
    SetterState,
    ArrayObject,
    HeadingText,
    ValueState,
    setcampaignsSearch,
    TextEnterData,
    setTextEnterData
  } = props;
  useBlurEffect(dropDownRef, setDropdownVisible);
  return (
    <>
      <div className="search-header-Drower-content searchContainer-top">
        <span className="search-header-Drower-Profession search-bar-input-padding ">
          {HeadingText}
        </span>
        {(HeadingText === "Campaigns" || HeadingText === "All candidates") && (
          <span className="Search-campaigns-input">
            <Input
              onChange={(e) => setcampaignsSearch(e.target.value)}
              placeholder="Search..."
              allowClear
              onClick={() => setDropdownVisible(HeadingText === "All candidates" ? !dropdownVisible : dropdownVisible)}
              value={TextEnterData ? TextEnterData : null}
            />
            {dropdownVisible && ArrayObject?.length > 0 && <div className="optionDropdownFilter" ref={dropDownRef}>
                {
                  ArrayObject?.length > 0 && ArrayObject?.map((data) => {
                    return (
                      <div className="PvtEmpName-cantiner">
                        <div onClick={() => { 
                            SetterState(data?.AccountId);
                            setDropdownVisible(false);
                            setTextEnterData(data?.PvtEmpName)
                          }} 
                          className="PvtEmpName-div">
                          {data?.PvtEmpName}
                        </div>
                      </div>
                    )
                  })
                }
            </div>}
          </span>
        )}
        <Radio.Group
          onChange={(e) => SetterState(e.target.value)}
          value={ValueState}
        >
          <Space direction="vertical">
            {ArrayObject?.data?.map((data, i) => {
              return (
                <>
                  <Radio
                    value={data?._id}
                    className="search-header-Drower-radio-one"
                    key={i}
                  >
                    <span className="search-header-Drower-radio-one-span">
                      {data?._id}
                    </span>
                    <span className="search-header-Drower-radio-one-span-1">
                      ({data?.count})
                    </span>
                  </Radio>
                </>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
    </>
  );
};
