import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import searchIcon from "../../../assets/icons/ant-design_search-outlined.svg";
import closeIcon from "../../../assets/icons/clarity_window-close-line.svg";
import { fetchDataFake } from "../../../redux/actions";
import SuggestionDropdown from "./SuggestionDropdown";

const SearchFilter = forwardRef(
  (
    {
      type,
      datePicker,
      searchQueryState: [searchQuery, setSearchQuery],
      apiCall,
    },
    ref
  ) => {
    const SearchManage =
      type === "talent" ? "suggestionTalent" : "suggestionEmp";
    const [hideIcon, setHideIcon] = useState(false);
    const [clearSearch, setClearSearch] = useState(false);
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const dispatch = useDispatch();
    const suggestionData = useSelector((state) => state.fetchDataReducer.user);
    const inputHandle = (e) => {
      e.stopPropagation();
      const searchStr = e.target.value;
      setSearchQuery(searchStr);
      if (searchStr.length) {
        setIsOpenDropdown(true);
        dispatch(
          fetchDataFake({
            SearchManage,
            SearchQuery: searchStr,
            //  datePicker
          })
        );
      } else {
        apiCall();
        setIsOpenDropdown(false);
        setClearSearch(false);
      }
    };

    const handleEvent = (e, ...callBackArr) => {
      e.stopPropagation();
      callBackArr.forEach((callBack) => {
        callBack[0](callBack[1]);
      });
    };

    const onEnterKeyPressHandle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      apiCall("", false, true);
      setIsOpenDropdown(false);
      setHideIcon(false);
      setClearSearch(true);
    };

    useImperativeHandle(
      ref,
      () => ({
        hideCloseIcon() {
          setClearSearch(false);
        },
      }),
      [clearSearch]
    );

    return (
      <>
        <div className="SearchFilterContainer">
          <img src={searchIcon} alt="" className="searchIcon" />
          <input
            type="text"
            placeholder="Search"
            className={"searchInput"}
            onChange={(e) => inputHandle(e)}
            value={searchQuery}
            onKeyDown={(e) => e.code === "Enter" && onEnterKeyPressHandle(e)}
            onFocus={(e) => handleEvent(e, [setHideIcon, true])}
            onBlur={(e) => handleEvent(e, [setHideIcon, false])}
          />
          {clearSearch && (
            <img
              src={closeIcon}
              alt=""
              className="closeIcon"
              onClick={(e) => {
                handleEvent(e, [setSearchQuery, ""], [setClearSearch, false]);
                apiCall("", true);
              }}
            />
          )}
          {suggestionData !== {} && (
            <SuggestionDropdown
              suggestionData={suggestionData}
              searchQueryState={[searchQuery, setSearchQuery]}
              isOpenDropdownState={[isOpenDropdown, setIsOpenDropdown]}
              setClearSearch={setClearSearch}
              apiCall={apiCall}
              type={type}
            />
          )}
        </div>
      </>
    );
  }
);

export default SearchFilter;
