import { call, put, takeEvery } from "redux-saga/effects";
import { fetchChatHistoryAPI, fetchTalentProfileAPI } from "../../API/API";
import {
  authUserTokendata,
  fetchTalentUserDetailsFailure,
  fetchTalentUserDetailsSuccess,
  fetchUserChatHistoryFailure,
  fetchUserChatHistorySuccess,
} from "../actions";
import { types } from "../action-types";

function* fetchTalentUserDetailsSaga(request) {
  try {
    const response = yield call(() => fetchTalentProfileAPI(request.payload)); // API Backend

    if (response?.status === 200) {
      yield put(fetchTalentUserDetailsSuccess(response.data));
    } else {
      yield put(fetchTalentUserDetailsFailure(response));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchTalentUserDetailsFailure(error));
  }
}

function* fetchUserChatHistorySaga(request) {
  try {
    const response = yield call(() => fetchChatHistoryAPI(request.payload)); // API Backend
    if (response?.response?.status === 200) {
      yield put(authUserTokendata(response?.headers?.Authorization))
      yield put(fetchUserChatHistorySuccess(response?.response.data.body));
    } else {
      yield put(fetchUserChatHistoryFailure(response?.response));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchUserChatHistoryFailure(error));
  }
}

export function* watchTalentUserDetailsSaga() {
  yield takeEvery(types.FETCH_TALENT_USER_DETAILS, fetchTalentUserDetailsSaga);
  yield takeEvery(types.FETCH_USER_CHAT_HISTORY, fetchUserChatHistorySaga);
}
