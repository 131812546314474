import React, { useState } from "react";
import { Modal, Avatar, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./EmployersModal.css";
import GreenTik from "../../../assets/icons/GreenTik.svg";
import { removeTalent } from "./../../../API/API";

const EmployersModal = ({
  updateData,
  data,
  isModalVisible,
  closeModal,
  redirectToEmployer
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingButtons, setLoadingButtons] = useState({});

  const handleCancel = (e) => {
    closeModal(false);
    e.stopPropagation();
  };
  const headerClick = (e)=>{
    e.stopPropagation();
  }
  const CustomHeader = ({ headerClick }) => (
    <div
    // style={{border:"2px solid black"}}
      className="custom-header"
      onClick={(e) => {
        e.stopPropagation();
        headerClick(e);
      }}
    >
      Private Employers
    </div>
  );
  const handleRemoveTalent = async (empDetails, index) => {
    const EmpName = empDetails.PvtEmpName;
    setLoadingButtons((prev) => ({ ...prev, [index]: true }));
    try {
      setLoading(true);
      const resp = await removeTalent(
        empDetails.AccountId,
        empDetails.talentId
      );
      if (resp.status === 200) {
        updateData();
        notification.open({
          message: (
            <div
              className="messageCon"
            >
             <span className="tikImage" ><img src={GreenTik} /></span> 
              <p
                className="RemovedText"
              >
                Removed from '{EmpName}' private marketplace successfully
              </p>
            </div>
          ),
          placement: "topLeft"
        });
        closeModal();
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message
      });
      console.warn("Error", error);
    } finally {
      setLoading(false);
      setLoadingButtons((prev) => ({ ...prev, [index]: false }));
    }
  };
  return (
    <div>
      <Modal
        
        // headerClick
        // title="Private Employers"
        visible={isModalVisible}
        onCancel={handleCancel}
        wrapClassName="custom-modal"
        footer={null}
      >
      <CustomHeader />
        {data?.length > 0 ? (
          data.map((item, index) => (
            <div key={index} className="Modal-Container">
              <div className="sub-container">
              <div className="namePicWrapper" >
                <div className="pvtModalPic">
                  <Avatar
                    src={
                      item?.PictureUrl
                        ? `${process.env.REACT_APP_S3_URL}/${item.PictureUrl}`
                        : null
                    }
                    size={24}
                    onClick={() => redirectToEmployer(item?.talentImpId, item?._id)}
                  >
                    {item?.PvtEmpName ? item.PvtEmpName[0] : "N/A"}
                  </Avatar>
                </div>
                <div
                  className="PvtModalName"
                  onClick={() => redirectToEmployer(item?.talentImpId)}
                >
                  {item?.PvtEmpName}
                </div>
              </div>  
              <span className="btnWrapper">
                <button
                  className="Leave-btn"
                  onClick={(e) => {e.stopPropagation(); handleRemoveTalent(item, index)}}                 
                  disabled={loadingButtons[index]}
                  style={{ cursor: "pointer" }}
                >
                  {loadingButtons[index] ? (
                    <Spin indicator={<LoadingOutlined spin />} size="small" />
                  ) : (
                    "Leave from private marketplace"
                  )}
                  {/* {loading===index ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : 'Leave from private marketplace'} */}
                </button>
              </span>
              </div>
            </div>
          ))
        ) : (
          <p>N/A</p>
        )}
      </Modal>
    </div>
  );
};

export default EmployersModal;
