import React, { useState } from "react";
import SearchHeader from "../../components/SearchHeader/SearchHeader";
import TableWithPagination from "../../components/Table/TableWithPagination";
import CardPlusPagination from "../Candidates/CardPlusPagination";

import { useHistory } from "react-router-dom";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";

const ManageEmployer = () => {
  const history = useHistory();
  const [globelPage, setglobelpage] = useState({
    page: 1,
    limit: window.innerWidth > 768 ? 10 : 15,
  });
  const [filterArr, setfilterArr] = useState([]);
  const [name,setName] = useState("");
  const type = "employer";

  return (
    <>
      <div className="ManageEmployerContainer">
        {window.innerWidth > 768 ? (
          <SearchAndFilter
            type={type}
            filterArr={filterArr}
            handlingPagination={[globelPage, setglobelpage]}
          />
        ) : (
          <div>
            <SearchHeader
              ManageEmployer="Manage Employer"
              handlingPagination={[globelPage, setglobelpage]}
              filterArr={filterArr}
              setName={setName}
            />
          </div>
        )}
        {window.innerWidth > 768 ? (
          <TableWithPagination
            type={type}
            handlingPagination={[globelPage, setglobelpage]}
            setfilterArr={setfilterArr}
          />
        ) : (
          <CardPlusPagination
            type={type}
            handlingPagination={[globelPage, setglobelpage]}
            setfilterArr={setfilterArr}
            name={name}
          />
        )}
      </div>
    </>
  );
};

export default ManageEmployer;
