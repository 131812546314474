import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchEmployerSuggestionSuccess,
  fetchTableFailure,
  fetchTableFilterCountSuccess,
  fetchTableFilterSuccess,
  fetchTableSuccess,
} from "../actions";
import { fetchPrivateTalentSuggestion, fetchTableApi } from "../../API/API";
import { getObjectFormat } from "../../components/Table/components/customTableFunction";

function* fetchTableSaga({ payload: { type, params } }) {
  try {
    const response = yield call(() => fetchTableApi(params));
    yield put(
      fetchTableSuccess({
        payload: response?.data[0] ? response?.data[0] : {},
        type,
      })
    );
    params =
      params.slice(0, params.lastIndexOf("/")) +
      "/Filter/Details" +
      params.slice(params.lastIndexOf("/"));
    const filterObj = yield getObjectFormat(
      params.slice(params.lastIndexOf("/") + 1)
    );  
    yield put(fetchTableFilterSuccess({ payload: filterObj, type }));
    const res = yield call(() => fetchTableApi(params));
    yield put(fetchTableFilterCountSuccess({ payload: res?.data[0], type }));
  } catch (error) {
    yield put(fetchTableFailure(error));
  }
}

function* fetchEmployerSuggestion({ payload }) {
  try {
    const response = yield call(() => fetchPrivateTalentSuggestion(payload));
    yield put(fetchEmployerSuggestionSuccess(response?.data))
  } catch (error) {
    yield put(fetchTableFailure(error));
  }
}

export function* watchFetchTableSaga() {
  yield takeEvery(types.FETCH_TABLE_INIT, fetchTableSaga);
}

export function* watchEmployerSuggestionSaga() {
  yield takeEvery(types.FETCH_PRIVATE_EMPLOYER, fetchEmployerSuggestion);
}
