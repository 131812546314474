import React, { useState } from "react";
import ProfileAvatar from "../ProfileAvatar";

import "./ChatHeader.css";

import { MdOutlineMail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";
import moment from "moment";

const ChatHeader = ({ currentUser }) => {
  const [LastActiveSocket, setLastActiveSocket] = useState();

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
  return (
    <>
      <div className="chat-header-main">
        <div className="chat-user-image">
          <ProfileAvatar
            name={currentUser?.Profiles?.FirstName}
            dot={true}
            size={45}
            src={`${process.env.REACT_APP_S3_URL}/${currentUser?.Profiles?.PictureUrl}`}
            isAvatarBgColor={true}
            setLastActiveSocket={setLastActiveSocket}
            IsOnlineAPI={currentUser?.IsOnline}
          />
        </div>
        <div className="username-chat-status">
          <div className="username-chat">
            {currentUser?.Profiles?.FirstName
              ? `${currentUser?.Profiles?.FirstName} ${currentUser?.Profiles?.LastName}`
              : "NA"}
          </div>
          <div className="userstatus-chat">
            {LastActiveSocket?.online
              ? "Online"
              : "Last active" + " " + (
                  moment(
                    LastActiveSocket?.lastActive
                      ? LastActiveSocket?.lastActive
                      : currentUser?.LastActive ? currentUser?.LastActive : currentUser?.LastLoginDate
                  ).fromNow()
                )}
          </div>
        </div>
      </div>
      <div className="email-phone-chat">
        <MdOutlineMail style={{ fontSize: "0.875rem", color: "#7E7E7E" }} />{" "}
        <div
          onClick={(e) => {
            window.location.href = `mailto:${currentUser?.EmailAddress}`;
          }}
          className="email-chat"
        >
          {currentUser?.EmailAddress ? currentUser?.EmailAddress : "NA"}
        </div>
        <BsTelephone style={{ fontSize: "0.875rem", color: "#7E7E7E" }} />{" "}
        <div className="phonenumber-chat">
          {currentUser?.Profiles?.MobilePhone
            ? "+" +
              currentUser?.Profiles?.MobilePhone?.slice(0, -10) +
              formatPhoneNumber(currentUser?.Profiles?.MobilePhone?.slice(-10))
            : "NA"}
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
