import { call, put, takeEvery } from "redux-saga/effects";
import { fetchProfileURL } from "../../API/API";
import { types } from "../action-types";
import { fetchUserProfileFailure, fetchUserProfileSuccess } from "../actions";

function* fetchProfileSaga(request) {
  try {
    const response = yield call(() => fetchProfileURL(request.payload)); // API Backend

    if (response.data.status === true) {
      yield put(fetchUserProfileSuccess(response.data.data.body.user));
    } else {
      yield put(fetchUserProfileFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchUserProfileFailure(error));
  }
}

export function* watchProfileSaga() {
  yield takeEvery(types.FETCH_PROFILE_INIT, fetchProfileSaga);
}
