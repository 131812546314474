import moment from "moment";
import TableCell from "./TableCell";
import TableHeaderTitle from "./TableHeaderTitle";
import { UserType } from "../../../shared/helper";
import PrivateGigTag from '../../../assets/icons/PrivateGigTag.svg'
export const AuthenticationProviderName = [
  "Local",
  "Facebook",
  "LinkedIn",
  "Google",
  "Yahoo",
  "Live",
  "GitHub",
  "Apple",
];

export const getObjectFormat = (params) => {
  const obj = {};
  params = params.split("&");
  obj["name"] = params[0].replace("?", "");
  for (let i = 1; i < params.length; i++) {
    const strArray = params[i].split("=");
    obj[strArray[0]] = strArray[1];
  }
  return obj;
};

export const getFilterObj = (obj) => {
  const defaultFilterArray = ["name", "page", "limit"];
  const defaultFilterObj = {};
  const filterObj = obj;
  defaultFilterArray.forEach((value) => {
    defaultFilterObj[value] = obj[value];
    delete filterObj[value];
  });

  return { defaultFilterObj, filterObj };
};

export const getSortTableData = (n, m, t, col, key) => {
  const a = n[col][key];
  const b = m[col][key];
  return t === "ascending"
    ? a.localeCompare(b)
    : t === "descending"
    ? b.localeCompare(a)
    : null;
};

export function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export const getParams = (defaultValue, filter = {}, isFilter) => {
  const {
    type,
    name = "",
    page = 1,
    limit = window.innerWidth < 768 ? 15 : 10,
    // StartDate = "05-17-2013",
    // EndDate = "11-18-2022",
  } = defaultValue;
  // let params = `/${type}/${name}?&StartDate=${StartDate}&EndDate=${EndDate}&page=${page}&limit=${limit}`;
  let params = `/${type}/${name}?&page=${page}&limit=${limit}`;
  if (isFilter && filter != {})
    for (let key in removeEmpty(filter)) {
      params = params + `&${key}=${filter[key]}`;
    }
  return params;
};

export const getDateTimeFormat = (dt) => {
  if (dt === "NA") return dt;
  const str = moment(dt).format("LLL").replace(",", "");
  const spaceIndex = str.lastIndexOf(" ");
  const dateTime =
    str.substring(0, spaceIndex) + "" + str.substring(spaceIndex + 1);
  return dateTime;
};

export const getValue = (value) => {
  return value ? value : "NA";
};

export const getColumns = (type, sortTableData, setmostTalentAssSort, mostTalentAssSort,updateData) => {
  // use enum values for user type from helper function
  const user = type === "talent" ? UserType.CANDIDATE : UserType.EMPLOYER;

  const columns = [
    {
      title: user + " Details",
      dataIndex: user,
      sorter: (a, b) => {
        return a[user]["Name"].localeCompare(b[user]["Name"])},
      width: "35%",
      render: (data) => (
        <TableCell data={data} columnsName={user} user={user} />
      ),
    },
    {
      title: <TableHeaderTitle title={"Company"} />,
      dataIndex: "Company",
      width: "20%",
      render: (data) => {
        return (
        <TableCell data={data} columnsName={"Company"} user={user} />
      )},
    },
    {
      title: <TableHeaderTitle title={"Employer Type"} />,
      dataIndex: "IsPrivateEmployer",
      width: "15%",
      sorter: (a,b) => {
        return !b.IsPrivateEmployer
      },
      render: (data) => {
        return(
        data ?
          <span>
            <img src={PrivateGigTag} />
          </span> : null
      )},
    },
    {
      title: <TableHeaderTitle title={"Profession"} />,
      dataIndex: "Prof",
      width: "10%",
      render: (data) => (
        <TableCell data={data} columnsName={"Prof"} user={user} />
      ),
    },
    {
      title: <TableHeaderTitle title={"Status"} />,
      dataIndex: "Status",
      width: "20%",
      render: (data) => (
        <TableCell data={data} columnsName={user + "Status"} user={user} />
      ),
    },
  ];


  if (type === "talent") {
    columns[0] = {
      ...columns[0],
      title: user + " Details",
      dataIndex: user,
    };
    columns[1] = {
      ...columns[1],
      title: <TableHeaderTitle title={"Info"} />,
      dataIndex: "Info",
      render: (data) => (
        <TableCell data={data} columnsName={"Info"} user={user} />
      ),
    };
    columns?.splice(1, 0, {
      title: <TableHeaderTitle title={"Private"} />,
      dataIndex: "EmployeeAssociation",
      sorter: (a,b) => { setmostTalentAssSort(!mostTalentAssSort)},
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: "10%",
      render: (data) => (
        <TableCell data={data} columnsName={"Private"} user={user} updateData={updateData}/>
      ),
    });

    // message column for candidate
    columns.push({
      title: <TableHeaderTitle title={"Message"} />,
      dataIndex: "Message",
      width: "20%",
      render: (data) => (
        <TableCell data={data} columnsName={user + "Message"} user={user} />
      ),
    });
  }
  return type == "talent" ? columns?.filter((data) => data?.dataIndex != "IsPrivateEmployer") : columns;
};

export const getColumnsData = (data, type) => {
  const user = type === "talent" ? "Candidate" : "Employer";

  const columnsData = data?.map((value, index) => {
    return {
      key: value?._id,
      AccountId: value?.AccountId,
      [user]: {
        Name: value?.Profiles?.FirstName
          ? `${value?.Profiles?.FirstName} ${
              value?.Profiles?.LastName ? value?.Profiles?.LastName : ""
            }`
          : value?.UserName
          ? value?.UserName
          : "NA",
        EmailAddress: getValue(value?.EmailAddress),
        MobilePhone: getValue(value?.Profiles?.MobilePhone),
        PictureUrl: getValue(value?.Profiles?.PictureUrl),
        UserID: getValue(value?._id),
        country_calling_codes: getValue(value?.country_calling_codes),
        LastLoginDate: getValue(value?.LastLoginDate),
        adminUnread: value?.adminUnread,
        conversationExists: value?.conversationExists,
        talentUnread: value?.conversationExists,
      },

      Company: {
        CompanyName: getValue(value?.Profiles?.CompanyName),
        UserID: getValue(value?._id),
      },
      IsPrivateEmployer:value?.IsPrivateEmployer,

      Prof: {
        ProfessionCode: getValue(value?.Professions?.ProfessionCode),
        UserID: getValue(value?._id),
      },

      Status: {
        Status: value?.Deactivated
          ? "Deactivated"
          : value.IsActive === null || value?.IsActive
          ? "Active"
          : "Inactive",
        Created: value?.Created,
        LastLoginDate: value?.LastLoginDate
          ? value?.LastLoginDate
          : value?.Created,
        UserID: getValue(value?._id),
      },
      EmployeeAssociation: (value?.EmployeeAssociation||[]).map(el=>({...el,talentId:value?.AccountId, talentImpId:value?._id})),
    };
  });

  if (type === "talent" && window.innerWidth > 769) {
    columnsData?.forEach((value, index) => {
      delete value.Company;
      const profileData = data[index]?.Profiles;
      value[user] = {
        ...value[user],
        Rating: getValue(profileData?.Rating),
        Review: getValue(),
        Location:
          profileData?.Location && profileData?.Location["Location"]
            ? profileData?.Location["Location"]
            : profileData?.Cities && profileData?.Cities["CityName"]
            ? `${profileData?.Cities["CityName"]}${
                profileData?.Regions && profileData?.Regions["RegionCode"]
                  ? `, ${profileData?.Regions["RegionCode"]}`
                  : ""
              }`
            : "NA",
        VisaStatusName: getValue(profileData?.VisaStatuses?.VisaStatusName),
        ResumeFileName: getValue(profileData.ResumeFileName),
      };

      value.Info = {
        AP: getValue(data[index]?.AuthenticationProviderId),
        Source: getValue(data[index]?.MCSource),
        Campaign: getValue(data[index]?.MCCampaign),
        Created: getValue(data[index]?.Created),
        UserID: getValue(value?._id),
      };

      value.Message = {
        CandidateId: value?.AccountId,
        adminUnread: value?.Candidate?.adminUnread,
        conversationExists: value?.Candidate?.conversationExists,
        talentUnread: value?.Candidate?.conversationExists,
        Status: value?.Status?.Status
      };
    });
  }

  return columnsData;
};
