import { Avatar } from 'antd';
import React ,{useState} from 'react';
import EmployersModal from './EmployersModal';
import { impersonate } from '../../../pages/Auth/Impersonation';
import { UserType } from '../../../shared/helper';

const PivateLogo = ({ data,updateData }) => {
    const [isModalVisible , setIsModalVisible]=useState(false)
    let closeModal=(e)=>{
        setIsModalVisible(e)
    }

    const redirectToEmployer = (id, companyId) => { 
        console.log('id', id)
        impersonate(id,UserType.EMPLOYER,`/company-profile/${companyId}`
 )
     }
    
    return (
        <div className="Private-cantiner" >
            {data?.length > 0 ? data?.map((data, index) => {
                if (index > 3) return;
                return (
                    <> 
                    <div className="PrivatePicName" style={{cursor:"pointer"}} onClick={()=>{setIsModalVisible(true)}}>
                        <span><Avatar src={data?.PictureUrl ? process.env.REACT_APP_S3_URL + '/' + data?.PictureUrl : null} style={{ fontSize: "14px" }} size={24}>{index > 2 ? data?.length - 2 : data?.PvtEmpName ? data?.PvtEmpName[0] : "N/A"}</Avatar></span>
                        <span  className='PrivatePicName-Pvt-Name'>{data?.PvtEmpName}</span>
                    </div>  
                    </>
                )
            }) : "N/A"}
            <EmployersModal redirectToEmployer={redirectToEmployer} data={data}  closeModal={closeModal} isModalVisible={isModalVisible} updateData={updateData} />
        </div>
    );
};

export default PivateLogo;