import { Pagination } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CommonPagination.css";
import PerPage from "./components/PerPage";

const CommonPagination = ({
  showTableData,
  type,
  handlingPagination: [{ page, limit }, setglobelpage],
}) => {
  // const { countData } =
  //   useSelector((state) => state.tableReducer.filterCount[type]) || [];
  const {
    user: {
      [type]: { data, TotalCount },
    },
  } = useSelector((state) => state.tableReducer) || [];
  // const totalCount = countData?.length ? countData[0]?.TotalValue[0]?.count : 1;

  const handlePagination = (currentPage) => {
    setglobelpage((prev) => ({ ...prev, page: currentPage }));
    showTableData({ page: currentPage, limit: limit });
    window.scrollTo(0, 0);
  };

  const handlePerPage = (perPage) => {
    setglobelpage((prev) => ({ page: 1, limit: perPage }));
    showTableData({ page: 1, limit: perPage });
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="CommonPaginationContainer">
        <div className="leftPaginationItem">
          <Pagination
            simple
            current={page ? page : 1}
            pageSize={limit ? limit : 10}
            total={TotalCount > 0 ? TotalCount : 1}
            // total={totalCount > 0 ? totalCount : 1}
            onChange={(currentPage) => handlePagination(currentPage)}
          />
        </div>
        <div className="rightPaginationItem">
          <PerPage selectedPerPage={limit} onChange={handlePerPage} />
        </div>
      </div>
    </>
  );
};

export default CommonPagination;
