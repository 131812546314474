import { all } from "redux-saga/effects";

import {
  watchCandiateCampaignsSaga,
  watchfetchCSVfileSaga,
  watchFetchDataSaga,
} from "./fetchDataSaga";
import { watchcheckUserSaga } from "./chekckUserSaga";
import { watchEmployerSuggestionSaga, watchFetchTableSaga } from "./fetchTableSaga";
import { watchProfileSaga } from "./fetchProfileSaga";
import { watchTalentUserDetailsSaga } from "./chatHistorySaga";

export default function* RootSaga() {
  yield all([
    watchFetchDataSaga(),
    watchcheckUserSaga(),
    watchFetchTableSaga(),
    watchCandiateCampaignsSaga(),
    watchfetchCSVfileSaga(),
    watchProfileSaga(),
    watchTalentUserDetailsSaga(),
    watchEmployerSuggestionSaga(),
  ]);
}
