import React, { useState, useEffect } from "react";

import "./UserContainer.css";

import ProfileAvatar from "../ProfileAvatar";
import CircularProgressBar from "./CircularProgressBar";

import { useSelector } from "react-redux";
import { Rate } from "antd";
import { useHistory } from "react-router-dom";

import { BiLinkExternal } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import { FiUserCheck } from "react-icons/fi";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";
import { impersonate } from "../../../Auth/Impersonation";
import { UserType } from "../../../../shared/helper";
import locationIcon from '../../../../assets/icons/akar-icons_location.svg'

const UserContainer = ({ currentUser }) => {
  const profile = currentUser?.Profiles;
  const DefaultsRating = 0;

  const history = useHistory();

  const [IsOnline, setIsOnline] = useState(true);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const user = useSelector((state) => state.profileReducer.user);

  const getCapitalisationFormat = (text) =>
    text
      .split(". ")
      .map((s) => s[0]?.toUpperCase() + s.slice(1)?.toLowerCase())
      .join(". ");

  const toTitleCaseGreater = (str) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const firstName = profile?.FirstName ? profile?.FirstName : "";

  const lastName = profile?.LastName ? profile?.LastName : "";

  const Name = firstName + " " + lastName;

  const resultCase = toTitleCaseGreater(Name);

  const Detail = useSelector((state) => state?.resumeDetailReducer?.success);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    const checkNetworkStatus = () => setIsOnline(navigator.onLine);

    setInterval(checkNetworkStatus, 5000);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  const userRating = (rating, isResumeCompletion) => {
    // rating = isResumeCompletion ? Number(rating) + 1 : rating;

    return rating >= 5 ? 5 : rating;
  };

  return (
    <div className="DashBoard-Profile-Container">
      <div className="DashBoard-Profile-User-Info">
        <div className="DashBoard-Profile-Avatar-section">
          <div className="DashBoard-Profile-Avatar-section-Mobile">
            <CircularProgressBar
              progress={
                Math.floor(profile?.ProfileComletion)
                  ? Math.floor(profile?.ProfileComletion)
                  : 0
              }
              size={dimensions > 1000 ? 100 : 80}
            />

            <div className="DashBoard-Profile-Avatar">
              <ProfileAvatar
                name={profile?.FirstName}
                dot={false}
                size={window.innerWidth > 1000 ? 70 : 56}
                src={`${process.env.REACT_APP_S3_URL}/${profile?.PictureUrl}`}
                isAvatarBgColor={true}
              />
            </div>
          </div>

          <div className="DashBoard-Profile-user-Mobile">
            <div className="DashBoard-Profile-user-name">
              {getCapitalisationFormat(resultCase)
                ? getCapitalisationFormat(resultCase)
                : "NA"}
            </div>
            <div className="DashBoard-Profile-user-Profession-Code">
              {currentUser?.Professions?.ProfessionCode
                ? currentUser?.Professions?.ProfessionCode
                : "NA"}
            </div>
            <div className="DashBoard-Profile-user-rating-section">
              <div className="DashBoard-Profile-user-rating-star">
                <Rate count={1} size="small" disabled defaultValue={1} />
              </div>

              <div className="DashBoard-Profile-user-rating-number">
                {userRating(
                  profile?.Rating ? profile?.Rating : DefaultsRating,

                  Math.floor(profile?.ResumeCompletion) === 100
                )}{" "}
                out of 5
              </div>
            </div>

            <div className="usercontainer-location-citizen">
              <img src={locationIcon} />{" "}
              <div style={{ marginRight: "0.6rem" }}>
                {profile?.Location?.Location
                  ? profile?.Location?.Location
                  : "NA"}
              </div>
              <FiUserCheck /> <div>{profile?.VisaStatuses?.VisaStatusName}</div>
            </div>
          </div>
        </div>
        <div
          className="view-dashboard-user"
          onClick={() => impersonate(currentUser?._id, UserType.CANDIDATE)}
        >
          View {toTitleCaseGreater(profile?.FirstName)}’s Dashboard <BiLinkExternal fontSize="1.25rem" />
        </div>
      </div>
    </div>
  );
};

export default UserContainer;
