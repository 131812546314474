import axios from "axios";
import { getCookie } from "../shared/helper";

const fetchDataUrl = "https://jsonplaceholder.typicode.com/posts";
const fetchCandiateResultValue = "https://dummyjson.com/products";
const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
const BackendAdminApiBaseUrl = process.env.REACT_APP_ADMIN_URL;
const fetchExistUserUrl = `${BackendApiBaseUrl}/auth/checkUser`;
const fetchProfileDetails = `${BackendApiBaseUrl}/profile/me`;
const fetchSearchSuggestion = `${BackendAdminApiBaseUrl}/employer`;
const fetchCampaignsResults = `${BackendAdminApiBaseUrl}/talent/suggestion`;
const fetchCSVdownload = `${BackendAdminApiBaseUrl}`;
const fetchMessagingUrl = `${BackendAdminApiBaseUrl}/message`;
const fetchSuggestionPrivate = `${BackendAdminApiBaseUrl}/talent/Filter/PrivateEmp`;
const removePrivateTalent = `${BackendAdminApiBaseUrl}/talent/removeEmployeeAssociation`;

let token = null;
let headers = null;

export const getToken = (params) => {
  const atoken = getCookie("adminToken");
  token = atoken ? atoken : params.payload;
  headers = { "Content-Type": "application/json", Authorization: token };
  return headers;
};

export const fetchDataApi = (params) => {
  const {
    SearchManage,
    SearchQuery,
    // datePicker: { StartDate, EndDate },
  } = params.payload;
  // const url = `${fetchSearchSuggestion}/${SearchManage}/${SearchQuery}?&StartDate=${StartDate}&EndDate=${EndDate}`;
  const url = `${fetchSearchSuggestion}/${SearchManage}/${SearchQuery}`;
  const headers = getToken();
  return axios.get(url, { headers });
};

export const fetchCampaignsDataApi = ({ params }) => {
  const {
    params: SearchQuery,
    datePicker: { StartDate, EndDate },
  } = params;
  const headers = getToken();
  return axios.get(
    `${fetchCampaignsResults}/Campagin?name=${SearchQuery}&StartDate=${StartDate}&EndDate=${EndDate}`,
    { headers }
  );
};

export const fetchCSVDataApi = ({ type, payload }) => {
  const headers = getToken();
  return axios.post(
    `${fetchCSVdownload}/${type}/exportCsv`,
    { ...payload },
    { headers }
  );
};

export const checkUserApi = async (request) => {
  // const data = JSON.stringify(params);
  const response = await axios.post(
    fetchExistUserUrl,
    { email: request.params },
    { headers }
  );
  return response;
};

export const fetchTableApi = async (params) => {
  const url = BackendAdminApiBaseUrl + params;
  const headers = getToken();
  const response = await axios.get(url, { headers });
  return response;
};

export const fetchProfileURL = async (params) => {
  headers = getToken(params);
  const response = await axios.get(fetchProfileDetails, {
    headers,
  });

  return response;
};

export const fetchTalentProfileAPI = async (talentId) => {
  const headers = getToken();

  const response = await axios.get(
    `${fetchMessagingUrl}/userprofile/${talentId}`,
    {
      headers,
    }
  );

  return response;
};

export const fetchChatHistoryAPI = async (params) => {
  const { UserId, adminId } = params;

  const headers = getToken();

  const response = await axios.get(
    `${fetchMessagingUrl}/conversations/${adminId}/${UserId}`,
    {
      headers,
    }
  );

  return {response, headers};
};

export const fetchPrivateTalentSuggestion = async (payload) => {
  const headers = getToken();
  const response = await axios.get(
    `${fetchSuggestionPrivate}?name=${payload}`,
    {
      headers,
    }
  );

  return response;
};

export const removeTalent = async (empId=null,talentId=null) => {
  const headers = getToken();
  return await axios.patch(
    `${removePrivateTalent}/${empId}/${talentId}`, {},
    { headers }
  );
};