import { combineReducers } from "redux";
import fetchDataReducer from "./fetchDataReducer";
import customHookReducer from "./customHookReducer";
import chekckUserReducer from "./chekckUserReducer";
import tableReducer from "./tableReducer";
import collapseReduser from "./collapseReducer";
import profileReducer from "./profileReducer";
import chatHistoryReducer from "./chatHistoryReducer";

export default combineReducers({
  fetchDataReducer,
  customHookReducer,
  chekckUserReducer,
  tableReducer,
  collapseReduser,
  profileReducer,
  chatHistoryReducer,
});
