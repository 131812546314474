import React, { useState, useEffect, useRef } from "react";
import "./ChatContainer.css";

import { Col } from "antd";

import ChatView from "./ChatView";
import MessageInput from "./MessageInput";
// import { WebSocketMain } from "../../../../WebSocket/defaultsocket";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
const url = process.env.REACT_APP_ADMIN_URL;


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export const ChatContainer = ({ chatHistory, messages, setMessages }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const currentUserToken = useSelector(
    (state) => state.chatHistoryReducer?.authToken
  );

  // Admin Websocket ///////////////////////////////////////////////////////////////////////////////////

  const [connectedState, setConnectedState] = useState(false);
  const [conversationIdState, setConversationState] = useState(null);

  const WebSocketMain = io(url, {
    extraHeaders: {
      authorization: currentUserToken, // Set the authorization header
    },
  });

  useEffect(() => {
    // Set up event listeners for incoming messages
    WebSocketMain.on("message", (response) => {
      // conversation id is needed from the second message
      if (!conversationIdState) {
        setConversationState(response?.data?.ConversationId);
      }
      if (
        response?.data?.SenderProfileId ==
          window?.location?.pathname?.split("/")?.slice(-1)[0] ||
        response?.data?.RecipientProfileId ==
          window?.location?.pathname?.split("/")?.slice(-1)[0]
      ) {
        setMessages((prevMessages) => [...prevMessages, response.data]);
      }
    });

    // listen for connection
    WebSocketMain.on("connect", () => {
      setConnectedState(true);
      console.log("User Connected");
    });

    // listen for disconnection
    WebSocketMain.on("disconnect", () => {
      setConnectedState(false);
      console.log("User Disconnected");
    });

    // Clean up when the component unmounts
    return () => {
      WebSocketMain.disconnect();
      setConversationState(null);
    };
  }, []);

  useEffect(() => {
    return () => {
      setMessages([]);
    };
  }, []);

  // below implementation help in reconnection, though socket-io already have auto reconnect feature
  // need to run more test for disconnection and then remove the code if not needed
  useEffect(() => {
    const handleMouseMove = (event) => {
      // This function is called whenever the mouse is moved.
      // You can access mouse coordinates using event.clientX and event.clientY.
      if (!connectedState) WebSocketMain.connect();
    };

    // Add the event listener to the document
    document.addEventListener("mousemove", handleMouseMove);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (!messages.length && chatHistory !== "Conversation Does not exsist!") {
      setConversationState(chatHistory[0]?.ConversationId);
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory !== "Conversation Does not exsist!"){
      setMessages(chatHistory)
    } else if (chatHistory == "Conversation Does not exsist!"){
      setMessages([])
    }
  }, [chatHistory]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="chatContainer"
      style={{
        height:
          windowDimensions.width < 769
            ? `${windowDimensions.height - 125}px`
            : null,
      }}
    >
      <ChatView chatDetails={messages} />
      <MessageInput WebSocketMain={WebSocketMain} connectedState={connectedState} conversationId={conversationIdState} />
    </div>
  );
};
