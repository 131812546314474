import React, { useState, useEffect, useRef } from "react";
import "./cardparent.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row } from "antd";
import AttachmentContainer from "./fileAttachment";
import { RiCheckDoubleLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useUpdateLogger } from "../../../../../shared/useUpdateLogger";

const CardParent = (props) => {
  const { userProfile } = props;

  let bidStatus;

  const dispatch = useDispatch();
  const history = useHistory();

  const dreason = useRef();
  const dreason1 = useRef();
  const dreason2 = useRef();

  //HOOKS****************
  const [scheduledTimings, setScheduledTimings] = useState(false);
  const [interviewDrawer, setinterviewDrawer] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [editOffer, setEditOffer] = useState();
  const [disabled, setDisabled] = useState(false);
  const [declineInterview, setDeclineInterview] = useState(false);
  const [reScheduleInterviewHook, setReScheduleInterviewHook] = useState(false);
  const [editScheduleInterviewHook, setEditScheduleInterviewHook] =
    useState(false);
  const [bidId, setBidId] = useState(false);
  const [bidType, setBidType] = useState(false);
  const [interviewId, setInterviewId] = useState(false);
  const [SkillListing_id, setSkillListing_id] = useState();
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState(false);
  const [modalText, setModalText] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [declinedReason, setDeclinedReason] = useState();

  const [ModalVisible, setModalVisible] = useState(false);

  const [ratingModal, setRatingModal] = useState(false);
  const [deleteRatingPop, setDeleteRatingPop] = useState(false);
  const [subscription, setSubs] = useState(false);

  const [offerCard, setOfferCard] = useState({});
  const [recipientUser, setRecipientUser] = useState({});
  const [ratings, setRatings] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  // useEffect(() => {
  //   if (chatDetails.data?.body) {
  //     if (chatDetails.data?.body[0].SourceTypeId == 1) {
  //       setBidId(chatDetails.data?.body[0].SecondarySourceId);
  //       setBidType(chatDetails.data?.body[0].SourceTypeId);
  //     }

  //     // // console.log("====== chat details", chatDetails);
  //     chatDetails.data?.body.map((card) => {
  //       if (card.Status == "Proposed Interview") {
  //         setScheduledTimings(card?.InterviewCard?.Interviews_ScheduledTimings);
  //       } else if (
  //         card.Status == "Offer Accepted" ||
  //         card.Status == "Offer Declined" ||
  //         card.Status == "Offer Pending" ||
  //         card.Status == "On Billing"
  //       ) {
  //         setOfferCard(card.OfferCard);
  //       } else if (card.Status == "Rating") {
  //         setRatings(card.Ratings);
  //       } else {
  //         //if (card.SecondarySourceTypeName == "Rating")
  //         setRecipientUser(card.RecipientUser);
  //       }
  //     });
  //     setSkillListing_id(chatDetails.data?.body[0].SkillListingCard?._id);
  //   }
  // }, [chatDetails.data?.body]);

  useEffect(() => {
    if (interviewId) {
      return;
    } else {
      setInterviewId(props.interviewCardID);
    }
  }, [props]);

  const declineReasonHandler = (e) => {
    setDeclinedReason(e.ReasonId);
  };

  const scheduleInterview = () => {
    setinterviewDrawer(true);
  };

  const reScheduleInterview = () => {
    // console.log("Yesss");
    setModalVisible(true);
    // setRejectModalVisible(true);
  };

  const editInterview = () => {
    setEditScheduleInterviewHook(true);
    setinterviewDrawer(true);
    setModalVisible(true);
  };

  const MakeOffer = (action) => {
    setOpenOfferModal(true);
    if (action === "edit") {
      // if (editResponse._id) {
      //    setEditOffer(editResponse)
      // }
      setEditOffer(props.OfferCards);
    }
    // if(action==='post'){
    //    setEditOffer('edit')
    // }
  };

  // const RejectCandidate = (status) => {
  //   if (status == "cancel") {
  //     dispatch(
  //       updateBid({
  //         id: bidId,
  //         BidStatusName: "Canceled",
  //         SkillListingId: SkillListing_id,
  //       })
  //     );
  //   } else if (status == "rejected") {
  //     setRejectModalVisible(true);
  //     setModalTitle("Reject this Candidate?");
  //     setModalText("Please select a reason for rejecting this Candidate");
  //   } else if (status == "terminated") {
  //     dispatch(
  //       updateBid({
  //         id: bidId,
  //         BidStatusName: "Terminated",
  //         SkillListingId: SkillListing_id,
  //       })
  //     );
  //   } else if (status == "interview cancelled") {
  //     setModalText("Please select a reason for cancelling this interview");
  //     setModalTitle("Cancel this interview?");
  //     setRejectModalVisible(true);
  //     setDeclineInterview(true);
  //   } else if (status == "terminate") {
  //     dispatch(
  //       updateBid({
  //         id: bidId,
  //         BidStatusName: "Terminated",
  //         SkillListingId: SkillListing_id,
  //       })
  //     );
  //   } else if (status === "Off Billing") {
  //     dispatch(
  //       updateBid({
  //         id: bidId,
  //         BidStatusName: "Off Billing",
  //         SkillListingId: SkillListing_id,
  //       })
  //     );
  //   }
  // };

  // function rejectModalClose(params) {
  //   setRejectModalVisible(false);
  // }

  // const ratingHandler = () => {
  //   history.push({
  //     pathname: "/rating_form",
  //     state: {
  //       bidId: chatDetails.data?.body[0]?.SecondarySourceId,
  //       image: recipientUser?.Profiles?.PictureUrl,
  //       candidate_name: recipientUser?.Profiles?.FirstName,
  //       Reviewee:
  //         chatDetails?.data?.body[0].SourceTypeId == 2
  //           ? chatDetails?.data?.body[0].RecipientUser?.AccountId
  //           : chatDetails?.data?.body[0].SenderUser?.AccountId,
  //       skill_listing_title: chatDetails.data?.body[0]?.SkillListingCard?.Title,
  //       companyName:
  //         chatDetails?.data?.body[0].SourceTypeId == 2
  //           ? chatDetails?.data?.body[0].RecipientUser?.Profiles?.CompanyName
  //           : chatDetails?.data?.body[0].SenderUser?.Profiles?.CompanyName,
  //       job_title: chatDetails.data?.body[0]?.GigCard?.Title,
  //       date_from: offerCard?.FromDate,
  //       date_to: offerCard?.ToDate,
  //       rating: false,
  //       previousScreen: "Messages",
  //       isEdit: false,
  //       conversationId: props.data.ConversationId,
  //       sourceName: props.data.SourceTypeId === 1 ? "Bid" : "Proposal",
  //     },
  //   });

  //   // setRatingModal(true);
  // };

  // const editRatingHandler = () => {
  //   // console.log("ratings data", ratings);
  //   history.push({
  //     pathname: "/rating_form",
  //     state: {
  //       bidId: chatDetails.data?.body[0]?.SecondarySourceId,
  //       image: recipientUser?.Profiles?.PictureUrl,
  //       candidate_name: `${recipientUser?.Profiles?.FirstName} ${recipientUser?.Profiles?.LastName}`,
  //       Reviewee:
  //         chatDetails?.data?.body[0].SourceTypeId == 2
  //           ? chatDetails?.data?.body[0].RecipientUser?.AccountId
  //           : chatDetails?.data?.body[0].SenderUser?.AccountId,
  //       skill_listing_title: chatDetails.data?.body[0]?.SkillListingCard?.Title,
  //       companyName:
  //         chatDetails?.data?.body[0].SourceTypeId == 2
  //           ? chatDetails?.data?.body[0].RecipientUser?.Profiles?.CompanyName
  //           : chatDetails?.data?.body[0].SenderUser?.Profiles?.CompanyName,
  //       job_title: chatDetails.data?.body[0]?.GigCard?.Title,
  //       date_from: offerCard?.FromDate,
  //       date_to: offerCard?.ToDate,
  //       rating: ratings,
  //       previousScreen: "Messages",
  //       isEdit: true,
  //       conversationId: props.data.ConversationId,
  //       sourceName: props.data.SourceTypeId === 1 ? "Bid" : "Proposal",
  //     },
  //   });

  //   // setIsEdit((prevState) => !prevState)
  //   // setRatingModal(true);
  // };

  const subsHandler = () => {
    setSubs(true);
  };

  const DeleteRatingHandler = () => {
    // // console.log("delete rating handler", props);
    setDeleteRatingPop(true);
  };

  // function HyperlinkParagraph({ text }) {
  //   const emailRegex = /(\S+@\S+\.\S+)/g;
  //   let linkRegex = /((https?:\/\/[^\s]+))/g;
  //   let linkParts = text?.split(' ')
  //   return (
  //     <p style={{lineBreak:'anywhere'}}>
  //       { linkParts?.length > 0 && (text.includes('http') || text.includes('@')) ?
  //         linkParts.map((part, i) => {
  //         if (emailRegex.test(part)) {
  //           return (
  //             <>
  //             &nbsp;
  //             <a key={i} href={`mailto:${part}`}>
  //               {part.replace()}
  //             </a>
  //             &nbsp;
  //             </>
  //           );
  //         } else if (/((https?:\/\/[^\s]+))/g.test(part)) {
  //           {/* console.log('part',part) */}
  //           return (
  //             <>
  //               &nbsp;
  //               {
  //                 part.split('\n').length > 0 && part.includes('\n') ?
  //                   part.split('\n').map((output, index) => {
  //                       return (
  //                         <>
  //                           {
  //                             <a
  //                             key={i}
  //                             href={output}
  //                             target="_blank"
  //                             rel="noopener noreferrer"
  //                           >
  //                             <span dangerouslySetInnerHTML={{ __html: output?.replaceAll('\n', '<br/>') }}></span><br/>
  //                           </a>
  //                           }
  //                         </>
  //                       );

  //                   })
  //                   :
  //                   <a
  //                     key={i}
  //                     href={part}
  //                     target="_blank"
  //                     rel="noopener noreferrer"
  //                   >
  //                     {part}
  //                   </a>
  //               }
  //               &nbsp;
  //             </>
  //           );
  //         } else {
  //           return <><span dangerouslySetInnerHTML={{ __html: part?.replaceAll('\n\n', '<br/></br/>') }}></span>&nbsp;</>
  //         }
  //       }):
  //        <div dangerouslySetInnerHTML={{ __html: text?.replaceAll('\n\n', '<br/></br/>') }}></div>
  //       }
  //     </p>
  //   );
  // }

  function HyperlinkParagraph({ text, sub }) {
    const tokens = text?.split(/[\r\n\s]+/);
    const separators = text?.match(/[\r\n]+|[\s]+/g);
    let output = "";

    for (let i = 0; i < tokens?.length; i++) {
      //test email regex
      if (/(\S+@\S+\.\S+)/g.test(tokens[i])) {
        output += `<a key=${i} href=mailto:${tokens[i]}>${tokens[i]}</a>`;
      }
      //test link regex
      else if (/((https?:\/\/[^\s]+))/g.test(tokens[i])) {
        output += `<a rel="noopener noreferrer" target="_blank" href=${tokens[i]}>${tokens[i]}</a>`;
      } else output += tokens[i];
      if (i < separators?.length) {
        // console.log(separators[i],"seperator");
        // if (/\n/.test(separators[i]) || /\r/.test(separators[i])) {
        //   output += "<br/>"
        // } else if (/(\r\n|\n|\r|[ ]{2})/.test(separators[i])) {
        //   output += "<br/>"
        // } else if (/[ ]{2}/.test(separators[i])) {
        //   output += "<br/>"
        // } else
        output += separators[i];
      }
    }
    // output = `<pre style=wrapWord:'break-word'>${output}</pre>`
    return (
      <div
        style={{ whiteSpace: "pre-line" }}
        className={ sub ? `Sub-Heading-CardParent` : null}
        dangerouslySetInnerHTML={{ __html: output }}
      ></div>
    );
  }

  return (
    <>
      {/* <RescheduleModal
        InterviewCard={props.interviewCardID}
        ModalVisible={ModalVisible}
        setModalVisible={setModalVisible}
      /> */}
      {/* <InterviewDrawer reSchedule={reScheduleInterviewHook} interviewId={interviewId} bid={bidId} timings={scheduledTimings} open={interviewDrawer} close={setinterviewDrawer} bidType={bidType} /> */}
      {/* <RejectModal text={modalText} title={modalTitle} /> */}
      {/* <Modal
        title={modalTitle}
        onCancel={rejectModalClose}
        visible={rejectModalVisible}
        footer={null}
      >
        <div className="main-class">
          <div className="declined-bid-modal">{modalText}</div>
          {declineReasons?.map((data) => (
            <div
              className={
                data.ReasonId === declinedReason
                  ? "bid-decline-option-selected"
                  : "bid-decline-option"
              }
              onClick={() => declineReasonHandler(data)}
            >
              {data.Reason}
            </div>
          ))}

          <button
            className="rejectModalBtn"
            onClick={() => {
              if (modalTitle == "Cancel this interview?") {
                dispatch(
                  InterviewScheduleAcceptedInit({
                    id: interviewId,
                    InterviewStatus: "Rejected",
                    // Reason: rejectReason,
                    ReasonId: declinedReason,
                  })
                );
              } else {
                declineInterview
                  ? dispatch(
                      InterviewScheduleAcceptedInit({
                        // id: "jasdkflj64657987987",
                        id: interviewId,
                        body: {
                          // ReScheduledTimings: [
                          //   {
                          //     StartTime: "2022-06-23T17:45:00.000+00:00",
                          //     EndTime: "2022-06-23T18:45:00.000+00:00",
                          //   },
                          //   {
                          //     StartTime: "2022-06-22T17:45:00.000+00:00",
                          //     EndTime: "2022-06-22T18:45:00.000+00:00",
                          //   },
                          // ],
                          InterviewStatus: "Rejected",
                          ReasonId: declinedReason,
                        },
                      })
                    )
                  : dispatch(
                      updateBid({
                        id: bidId,
                        BidStatusName: "Offer Declined",
                        SkillListingId: SkillListing_id,
                        DeclineReason: rejectReason,
                      })
                    );
              }
              setRejectModalVisible(false);
            }}
          >
            Submit reason
          </button>
        </div>
      </Modal> */}
      {/* <DeleteRatingsModal
        visible={deleteRatingPop}
        BidId={props?.BidId}
        bidType={props?.bidType}
        Ratings_data={props.Ratings_data}
        setDelModalVisible={setDeleteRatingPop}
      /> */}
      {/* <OfferModal
            visible={openOfferModal}
            setOpenOfferModal={setOpenOfferModal}
            bidID={props.bid_id}
            editOffer={editOffer}
            setEditOffer={setEditOffer}
            setDisabled={setDisabled}
            data={props.data}
         /> */}
      <div style={{ width: "22rem" }}>
        <div
          className="Plain-text-msg-align"
          style={{
            backgroundColor:
              props.status == "Accepted"
                ? "#e7f3eb"
                : props.status == "Interview Cancelled" ||
                  props.status == "Candidate Cancelled" ||
                  props.status == "Candidate Terminated" ||
                  props.status == "Declined Bid"
                ? "#fff2f2"
                : props.htmlValue == true
                ? "#f2f2f2"
                : props.status == "Offer Declined"
                ? "#fff2f2"
                : "#F2F2F2",
            padding: ".625rem",
            width: "100%",
            wordBreak: "break-word",
            borderRadius: ".3125rem",
          }}
        >
          {props.htmlValue == false ? (
            props.children
          ) : (
            <>
              {props?.subject ? (
                <div className="subject-font-chat">
                  <HyperlinkParagraph text={props?.subject} />
                </div>
              ) : null}
              <HyperlinkParagraph text={props.children} sub={true} />
              {props.attachments?.length > 0 &&
                props.attachments.map((files) => {
                  return (
                    <AttachmentContainer
                      fileName={files.FileName}
                      filePath={files.FilePath}
                      fileFileSize={files.FileSize}
                    />
                  );
                })}
            </>
          )}

          <div
            style={{
              display: "flex",
              // marginTop: "3%",
              color: "#b4b4b4",
              fontSize: "14px",
              // marginRight: "1%",
              justifyContent : "flex-end",
              fontWeight: "400",
            }}
          >
            <div className="msg-time-stamp" >{moment(props.Created).format("hh:mm A")}</div>
            {(userProfile?.AccountId == props?.data?.SenderUser?.AccountId && props?.data?.ReadAt != null) || props?.ReadByTalentAdmin?.includes(props?.data?.MessageGUID)  ? (
              <RiCheckDoubleLine className="double-check-icon" />
            ) : <RiCheckDoubleLine className={props?.ReadByTalentAdminAll ? "double-check-icon" : "double-check-icon-gray"} />}
          </div>
        </div>

        {/* {props.btns && props.status == "Proposed Interview" ? (
          <div className="chat-card-btns">
            <Button
              disabled={bidStatus.includes("Accepted Interview")}
              className="btns"
              onClick={editInterview}
            >
              Edit
            </Button>
            <Button
              disabled={bidStatus.includes("Accepted Interview")}
              className="btns"
              onClick={() => RejectCandidate("interview cancelled")}
            >
              Cancel
            </Button>
          </div>
        ) : */}
        {(userProfile?.AccountId != props?.items?.SenderUser?.AccountId &&
          props.status == "Scheduled Interview") ||
        props.status == "Interview rescheduled" ? (
          <div className="chat-card-btns">
            {/* <Button
              className="btns"
              disabled={bidStatus.includes("Offer Pending")}
              onClick={() => reScheduleInterview()}
            > */}
            {/* (props.status).includes("Interview rescheduled") */}
            {/* Reschedule
            </Button>
            <Button
              className="btns"
              disabled={bidStatus.includes("Offer Pending")}
              onClick={() => RejectCandidate("interview cancelled")}
            >
              Decline
            </Button> */}
          </div>
        ) : props.status == "On Billing" && props.SkillListingCard ? (
          <div>
            <Row className="chat-card-btns">
              {/* <button className="btns" onClick={() => dispatch(editJobOffer({ Status: "Off Billing" }, { id: offerCard._id, }))}>Move to Off Billing</button> */}
              <button
                className="btns"
                onClick={() => history.push("/resume-3d")}
              >
                Extend contract
              </button>
            </Row>
            {/* <div className="chat-three-btns">
              <button className="btns" onClick={ratingHandler}>
                Rate the candidate
              </button>
            </div> */}
          </div>
        ) : (
          (props.status == "Off Billing" || props.status == "On Billing") && (
            <div className="chat-three-btns">
              {/* <button className="btns" onClick={ratingHandler}>
                Rate the Employer
              </button> */}
            </div>
          )
        )}

        {props.threebtns && (
          <div className="chat-three-btns">
            <Button
              className="btns"
              onClick={scheduleInterview}
              disabled={
                bidStatus.includes("Proposed Interview") ||
                bidStatus.includes("Offer Pending") ||
                bidStatus.includes("Offer Declined")
              }
            >
              Schedule interview
            </Button>
            {/* <Button
              className="btns"
              onClick={() => MakeOffer("post")}
              disabled={
                bidStatus.includes("Proposed Interview") ||
                bidStatus.includes("Offer Pending") ||
                bidStatus.includes("Offer Declined")
              }
            >
              Make offer
            </Button>
            <Button
              className="btns"
              onClick={() => RejectCandidate("rejected")}
              disabled={
                bidStatus.includes("Proposed Interview") ||
                bidStatus.includes("Offer Pending") ||
                bidStatus.includes("Offer Declined")
              }
            >
              Reject
            </Button> */}
          </div>
        )}
        {props.status == "Share your feedBack" && props?.SkillListing_id && (
          <div className="chat-three-btns">
            {/* <Button
              className="btns"
              onClick={() => MakeOffer("post")}
              disabled={
                bidStatus.includes("Offer Pending") ||
                bidStatus.includes("Offer Declined")
              }
            >
              Make offer
            </Button>
            <Button
              className="btns"
              onClick={() => RejectCandidate("rejected")}
              disabled={
                bidStatus.includes("Offer Pending") ||
                bidStatus.includes("Offer Declined")
              }
            >
              Reject
            </Button> */}
          </div>
        )}
        {props.ratings &&
          userProfile?.AccountId == props?.data?.SenderUser?.AccountId && (
            <div className="onBilling-card-btns">
              {/* <button className="edit-ratings" onClick={editRatingHandler}>
                Edit Rating
              </button> */}
              {/* <button className="delete-ratings" onClick={DeleteRatingHandler}>
              Delete rating
            </button> */}
            </div>
          )}
        {/* {props.status === "Offer Pending" && (
          <div className="chat-card-btns">
            <button className="edit-btn" onClick={() => MakeOffer("edit")}>
              Edit
            </button>
          </div>
        )} */}

        {/* {props.status === "Offer Accepted" && (
          <div className="chat-three-btns">
            <Button
              className="btns"
              onClick={() => {}}
              disabled={bidStatus.includes("Hired")}
            >
              Review credentialing
            </Button>
            <Button
              disabled={bidStatus.includes("Hired")}
              className="btns"
              onClick={() => dispatch(editJobOffer({ Status: "Hired" }, { id: offerCard._id, }))}
            >
              Move to hired
            </Button>
          </div>
        )} */}
      </div>
    </>
  );
};
export default CardParent;
