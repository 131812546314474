import React, { useState, useEffect } from "react";
import { Row, Skeleton } from "antd";
import "./ChatView.css";
import CardParent from "../../../Conversations/components/ChatCard/components/CardParent";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";
import { useSelector } from "react-redux";

import nochatview from "../../../../assets/icons/No-Chat.svg";
import moment from "moment";
import { WebSocketMain } from "../../../../WebSocket/defaultsocket";

const ChatView = ({ chatDetails }) => {
  const [history, setHistory] = useState();
  const [LoadChat, setLoadChat] = useState(false);
  const [LoadChatgroup, setLoadChatgroup] = useState();
  const [ReadByTalentAdmin, setReadByTalentAdmin] = useState([]);
  const [ReadByTalentAdminAll, setReadByTalentAdminAll] = useState(false);

  //   dummy;
  const chatHistoryLoading = useSelector(
    (state) => state.chatHistoryReducer?.loading
  );
  const chatHistory = useSelector(
    (state) => state.chatHistoryReducer?.chatHistory
  );
  let loading;

  const userProfile = useSelector((state) => state.profileReducer.success);
  function chatDataTime(date){
    const inputDate = moment(date[0]?.Created);
    const currentDate = moment();
    const isToday = inputDate.isSame(currentDate, 'day');
    const formattedDate = isToday
      ? 'Today'
      : inputDate.format('MMM DD');
    return formattedDate
  }

  


  useEffect(() => {
    // setTimeout(() => {
    //   setLoadChat(false);
    // }, 400);
    setLoadChat(true);
    if (chatDetails?.length > 0) setHistory([...chatDetails].reverse());
    setLoadChat(false);

    console.log("history:chat", chatDetails);
    return () => {
      setHistory([]);
    };
  }, [chatDetails]);

  useEffect(() => {
    if (history) {
      const result = Object?.groupBy(history, (items) => moment(items?.Created)?.format('YYYY-MM-DD'));
      const reversedGroups = Object.values(result).map(group => group.reverse());
      setLoadChatgroup(reversedGroups);
    }
    return () => {
      setLoadChatgroup([]);
    };
  }, [history])

  useEffect(() => {
    WebSocketMain.on("messageStatusUpdate", (response) => {
      setReadByTalentAdmin(oldArray => [...oldArray, response?.MessageGUID]);
    });
  }, []);

  useEffect(() => {
    if (chatDetails?.length > 0) {
      setReadByTalentAdminAll(ReadByTalentAdmin?.includes(chatDetails[chatDetails?.length - 1]?.MessageGUID))
    }
  }, [chatDetails, ReadByTalentAdmin])

  return (
    <>
      {chatDetails.length === 0 &&
      !chatHistoryLoading &&
      chatHistory == "Conversation Does not exsist!" ? (
        <div className="empty-conversation-chat">
          <img src={nochatview} alt="no-chat-view" style={{ height: "5rem" }} />
          <div className="no-messages-text">
            No messages yet. Start chatting with the candidate.
          </div>
        </div>
      ) : (
        <div
          className="chatcard"
          aria-details={chatDetails?.length > 1 && "more-than-one-msg"}
          key={Math.floor(Math.random() * 999)}
        >
          {chatHistoryLoading || LoadChat || !LoadChatgroup ? (
            <>
              <div className="InnerChatCont">
                <Row className="spaceTop">
                  <div className="left">
                    <CardParent htmlValue={false}>
                      <Skeleton active />
                    </CardParent>
                  </div>
                  <div className="right" style={{ marginTop: "3%" }}>
                    <CardParent htmlValue={false}>
                      <Skeleton active />
                    </CardParent>
                  </div>
                </Row>
              </div>
            </>
          ) : (
            <>
              {LoadChatgroup?.map((data,key) => {
                return (
                  <div key={key}>
                    <div className="chatDataTime-Admin" >{chatDataTime(data)}</div>
                    {data?.map((items, index) => {
                      if (items?.SenderLabels !== null) {
                        var nonNullIndex = history
                          .slice(0, index + 1)
                          .reduce(
                            (count, currentItem) =>
                              count + (currentItem?.SenderLabels !== null),
                            0
                          );
                      }                      
                      return (
                        <div className="InnerChatCont" key={items?._id}>
                          <Row className="spaceTop">
                            <div
                              className={
                                items?.SenderUser?.AccountId
                                  ? userProfile?.AccountId ===
                                    items?.SenderUser?.AccountId
                                    ? "right"
                                    : "left"
                                  : userProfile?.AccountId ===
                                    items?.SenderProfileId
                                  ? "right"
                                  : "left"
                              }
                            >
                              <CardParent
                                htmlValue={true}
                                subject={items?.Subject}
                                threebtns={false}
                                attachments={items?.Attachments}
                                Created={items?.Created}
                                data={items}
                                userProfile={userProfile}
                                ReadByTalentAdmin={ReadByTalentAdmin}
                                ReadByTalentAdminAll={ReadByTalentAdminAll}
                              >
                                {items?.MessageText}
                              </CardParent>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ChatView;
