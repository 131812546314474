import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./Messaging.css";

import UserContainer from "./Components/UserContainer/UserContainer";
import ChatHeader from "./Components/ChatContainer/ChatHeader.js";
import { ChatContainer } from "./Components/ChatContainer/ChatContainer";

import { BiArrowBack } from "react-icons/bi";
import {
  clearUserChatHistory,
  fetchTalentUserDetails,
  fetchUserChatHistory,
} from "../../redux/actions";
import { useUpdateLogger } from "../../shared/useUpdateLogger";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Messaging = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const UserId = useParams()?.id;
  const [messages, setMessages] = useState([]);

  const adminId = useSelector(
    (state) => state.profileReducer?.success?.AccountId
  );
  const currentUser = useSelector(
    (state) => state.chatHistoryReducer?.currentUser
  );
  const chatHistory = useSelector(
    (state) => state.chatHistoryReducer?.chatHistory
  );

  useEffect(() => {
    dispatch(fetchTalentUserDetails(UserId));
  }, []);

  useEffect(() => {
    if (adminId) dispatch(fetchUserChatHistory({ UserId, adminId }));
  }, [adminId, UserId]);

  return (
    <div className="messaging-main">
      <div
        className="messaging-go-back"
        onClick={() => { history.push("/manageCandidate"); setMessages([]) ; dispatch(clearUserChatHistory([]))}}
      >
        <BiArrowBack fontSize="1.25rem" />
        Go back to Manage Candidate
      </div>
      <div className="messaging-userprofile-container">
        <div className="messaging-container-main">
          <ChatHeader currentUser={currentUser} />
          <ChatContainer setMessages={setMessages} messages={messages} chatHistory={chatHistory} />
        </div>
        <UserContainer currentUser={currentUser} />
      </div>
    </div>
  );
};

export default Messaging;
