import { getCookie } from "../../shared/helper";
import { types } from "../action-types";

const talentToken = getCookie("adminToken");

const initialState = {
  loading: false,
  currentUser: null,
  chatHistory: [],
  error: {},
  authToken: talentToken ? talentToken : null,
};

const chatHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TALENT_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TALENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
      };

    case types.FETCH_TALENT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_USER_CHAT_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_USER_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        chatHistory: action.payload,
      };

    case types.FETCH_USER_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.CLEAR_USER_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: action.payload
      };
    case types.AUTH_USER_TOKEN:
      return {
        ...state,
        authToken: action.payload
      };

    default:
      return state;
  }
};

export default chatHistoryReducer;
